import { css } from '@amedia/brick-tokens';

export const helpTextStyle = css({
  color: '$baseNeutral600',
  margin: '0 0 4px',
});

export const errorTextStyle = css({
  color: '$utilityDangerBorder',
  margin: '0 0 4px',
});

export const optionalTextStyle = css({
  color: '$baseNeutral600',
});

export const inputStyle = css({
  appearance: 'none',
  boxSizing: 'border-box',
  fontstyle: 'baseUtilityS',
  color: '$baseNeutral900',
  padding: '10px',
  width: '100%',
  border: '1px solid $baseNeutral400',
  borderRadius: '4px',
  backgroundColor: '$baseNeutral00',
  outline: 'none',
  '&:where([type="range"])': {
    appearance: 'auto',
  },
  '&:where([type="color"], [type="image"])': {
    padding: '0',
  },
  '&:hover': {
    borderColor: '$baseNeutral700',
  },
  '&:active': {
    borderColor: '$baseNeutral900',
  },
  '&:focus-visible': {
    outline: '#316fd1 solid 3px',
    outlineOffset: '2px',
  },
  '&:disabled': {
    backgroundColor: '$baseNeutral50',
    borderColor: '$baseNeutral400',
    color: '$baseNeutral400',
  },

  '&[data-disabled="true"] .optional': {
    color: '$baseNeutral300',
  },
  '&::placeholder': {
    fontstyle: 'baseUtilityS',
    color: '$baseNeutral400',
  },
  /* RADIO BUTTON */
  '&[type="radio"]': {
    borderRadius: '50%',
    position: 'relative',
    border: '1px solid $baseNeutral400',
    cursor: 'pointer',
  },
  '&[type="radio"]:checked': {
    borderColor: '$baseNeutral700',
  },
  '&[type="radio"]::before': {
    content: "''",
    position: 'absolute',
    width: '0',
    height: '0',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: '0.1s all linear',
  },
  '&[type="radio"]:checked::before': {
    content: "''",
    position: 'absolute',
    width: '10px',
    height: '10px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '$baseNeutral700',
    borderRadius: '50%',
  },
  '&[type="radio"]:disabled': {
    borderColor: '$baseNeutral300',
  },

  '[data-disabled="true"] &[type="radio"]': {
    borderColor: '$baseNeutral300',
  },
  '[data-disabled="true"] &[type="radio"]:checked::before': {
    backgroundColor: '$baseNeutral300',
  },
  '&[type="checkbox"], &[type="radio"]': {
    height: '16px',
    width: '16px',
    padding: '0',
    margin: '0 0 10px',
    fontSize: 'revert',
  },
  /* CHECKBOX */
  '&[type="checkbox"]': {
    appearance: 'none',
    position: 'relative',
    border: '1px solid $baseNeutral400',
    borderRadius: '2px',
    cursor: 'pointer',
  },
  '&[type="checkbox"]:checked': {
    borderColor: '$baseNeutral700',
    backgroundColor: '$baseNeutral900',
  },
  '&[type="checkbox"]::before': {
    content: "''",
    position: 'relative',
    opacity: '0',
    transition: '0.1s opacity linear',
  },
  '&[type="checkbox"]:checked::before': {
    content: "'✓'",
    fontSize: '14px',
    color: '$baseNeutral100',
    position: 'absolute',
    top: '-1px',
    left: '2px',
    opacity: '1',
  },
  '&[type="checkbox"]:disabled': {
    borderColor: '$baseNeutral300',
  },
  '[data-disabled="true"] &': {
    cursor: 'not-allowed',
  },
  '[data-disabled="true"] &[type="checkbox"]': {
    borderColor: '$baseNeutral300',
  },
  '[data-disabled="true"] &[type="checkbox"]:checked::before': {
    backgroundColor: '$baseNeutral300',
  },
  '&[aria-invalid="true"]': {
    borderColor: '$utilityDangerBorder',
  },
});

/* LABEL */
export const labelStyle = css({
  fontstyle: 'baseUtilityXs',
  color: '$baseNeutral900',
  display: 'block',

  '[data-disabled="true"] &': {
    color: '$baseNeutral400',
    cursor: 'not-allowed',
  },

  '[data-type="radio"] &': {
    cursor: 'pointer',
  },

  '[data-type="radio"][data-disabled="true"] &': {
    color: '$baseNeutral400',
  },

  '[data-type="checkbox"] &': {
    cursor: 'pointer',
  },

  '[data-type="checkbox"][data-disabled="true"] &': {
    color: '$baseNeutral400',
  },
});
